import moment from "moment";

moment.locale('fr');

function getCurrentWeekNumber() {
    return moment(new Date()).week();
}

function getFormatedWeek(weekNumber, dateFormat) {
    let date = moment().isoWeek(weekNumber || 1).startOf("week"), weekLength = 7, result = [];
    while (weekLength--) {
        result.push(date.format(dateFormat));
        date.add(1, "day")
    }
    return result;
}

function getWeekDaysByWeekNumber(weekNumber) {
    const dateFormat = "dddd DD";
    let days = getFormatedWeek(weekNumber, dateFormat);
    return days.map(d => ({
        dayName: d.split(' ')[0],
        dayNumber: d.split(' ')[1]
    }));
}

function getWeekDatesByWeekNumber(weekNumber) {
    const dateFormat = "DD/MM/YYYY";
    if (!weekNumber) {
        weekNumber = moment(new Date()).week();
    }
    return getFormatedWeek(weekNumber, dateFormat);
}

function getMonthOfWeek(weekNumber) {
    return weekNumber;
}

function getFirstDayOfWeek(weekNumber) {
    return moment().isoWeek(weekNumber).startOf('week').format('ll');
}

function getLastDayOfWeek(weekNumber) {
    return moment().isoWeek(weekNumber).endOf('week').format('ll');
}

export const timeService = {
    getCurrentWeekNumber,
    getWeekDaysByWeekNumber,
    getWeekDatesByWeekNumber,
    getFirstDayOfWeek,
    getLastDayOfWeek,
    getMonthOfWeek
};