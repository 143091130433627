<template>
  <div class="timeline">
    <div class="spacer"></div>
    <div class="time-marker">7h</div>
    <div class="time-marker"></div>
    <div class="time-marker">8h</div>
    <div class="time-marker"></div>
    <div class="time-marker">9h</div>
    <div class="time-marker"></div>
    <div class="time-marker">10h</div>
    <div class="time-marker"></div>
    <div class="time-marker">11h</div>
    <div class="time-marker"></div>
    <div class="time-marker">12h</div>
    <div class="time-marker"></div>
    <div class="time-marker">13h</div>
    <div class="time-marker"></div>
    <div class="time-marker">14h</div>
    <div class="time-marker"></div>
    <div class="time-marker">15h</div>
    <div class="time-marker"></div>
    <div class="time-marker">16h</div>
    <div class="time-marker"></div>
    <div class="time-marker">17h</div>
    <div class="time-marker"></div>
    <div class="time-marker">18h</div>
    <div class="time-marker"></div>
    <div class="time-marker">19h</div>
    <div class="time-marker"></div>
    <div class="time-marker">20h</div>
  </div>
</template>
<script>
export default {
  name: "TimeLine",
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">

.timeline {
  display: grid;
  grid-template-rows: repeat(27, 30px);

  .space,
  .date {
    height: 30px
  }
}

</style>
