// Stutends mutations
export const REFRESH_STUDENTS = 'REFRESH_STUDENTS';
export const PURGE_STUDENTS = 'PURGE_STUDENTS';

// Events mutations
export const REFRESH_EVENTS = 'REFRESH_EVENTS';
export const PURGE_EVENTS = 'PURGE_EVENTS';

// Auth mutations
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Time mutations
export const SET_SELECTED_WEEK = 'SET_SELECTED_WEEK';
export const SET_SELECTED_DAYS = 'SET_SELECTED_DAYS';
export const SET_SELECTED_MONTH = 'SET_SELECTED_MONTH';
export const SET_FIRST_DAY = 'SET_FIRST_DAY';
export const SET_LAST_DAY = 'SET_LAST_DAY';
export const DECREMENT_SELECTED_WEEK = 'DECREMENT_SELECTED_WEEK';
export const INCREMENT_SELECTED_WEEK = 'INCREMENT_SELECTED_WEEK';
