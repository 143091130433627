<template>
  <form @submit="addEvent" name="event-form">
    <div class="card event-form-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Ajouter un évènement</p>
      </header>
      <div class="card-content">
        <div class="time-params">
          <div class="date-area">
            <div class="title-date">Date</div>
            <v-date-picker class="inline-block h-full date-picker" color="purple" v-model="date">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <input type="text" :value="inputValue" @click="togglePopover" class="input" readonly/>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="hour-area">
            <div class="title-hour">
              Heure
            </div>
            <div class="hour-group">
              <div class="select">
                <select v-model="startingHour">
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                </select>
              </div>
              <span class="hour-points">:</span>
              <div class="select">
                <select v-model="startingMinutes">
                  <option value="0">00</option>
                  <option value="0.5">30</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="classe-param">
          <div class="type">
            <div class="title-type">
              Type
            </div>
            <div class="select">
              <select v-model="type">
                <option value="classe">Leçon</option>
                <option value="exam">Examen</option>
                <option value="pedameet">RDV Pédagogique</option>
                <option value="preameet">RDV Préalable</option>
              </select>
            </div>
          </div>
          <div class="category">
            <div class="title-category">Catégorie</div>
            <div class="select">
              <select v-model="category" @change="resetSelectedStudents">
                <option value="B">B</option>
                <option value="AAC">AAC</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="A">A</option>
                <option value="AM">AM</option>
                <option value="BE">BE</option>
                <option value="B96">B96</option>
              </select>
            </div>
          </div>
          <div class="duration">
            <div class="title-category">Durée</div>
            <div class="select">
              <select v-model="duration">
                <option value="0.5">00:30</option>
                <option value="1">01:00</option>
                <option value="1.5">01:30</option>
                <option value="2">02:00</option>
                <option value="2.5">02:30</option>
                <option value="3">03:00</option>
                <option value="3.5">03:30</option>
                <option value="4">04:00</option>
              </select>
            </div>
          </div>
        </div>
        <div class="students">
          <div class="selected-stuents">
            <div>Elèves sélectionnés</div>
            <div class="selected-students-chip">
              <div v-for="student of selectedStudents" :key="student.id" class="chip">
                {{ student.lastname }} {{ student.firstname }}
                <span class="close-btn" @click="unselectStudent(student)">&times;</span>
              </div>
            </div>
            <div v-if="!selectedStudents.length" class="warning">Aucun élève sélectionné</div>
          </div>
          <div class="unselected-students">
            <div>Elèves disponibles</div>
            <input type="text" v-model="name" class="input" placeholder="Rechercher par nom ou prénom"/>
            <div class="select-user">
              <div v-for="student of students(name, category, selectedStudents)" :key="student.id"
                   value="{{student.id}}"
                   @click="selectStudent(student)" class="student-name">{{ student.lastname }} {{ student.firstname }}
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <button type="submit" class="button is-primary card-footer-item"
                  :disabled="!this.date || !this.category || !this.type">AJOUTER
          </button>
        </footer>
      </div>
    </div>
  </form>
</template>

<script>
import {getAuth} from "firebase/auth";
import store from "../../store";
import {mapGetters} from "vuex";
import _remove from "lodash/remove";
import moment from "moment";

export default {
  name: "EventForm",
  emits: ['closeModal'],
  data() {
    return {
      date: new Date(),
      startingHour: 8,
      startingMinutes: 0,
      duration: 1.5,
      category: "B",
      type: "classe",
      selectedStudents: [],
      name: null,
    }
  },
  computed: {
    ...mapGetters({
      students: 'getUnselectedStudentsByName',
    }),
  },
  created() {
  },
  methods: {
    initForm() {
      this.date = new Date();
      this.startingHour = 8;
      this.startingMinutes = 0;
      this.duration = 1.5;
      this.category = "B";
      this.type = "classe";
      this.selectedStudents = [];
      this.name = null;
    },
    addEvent() {
      store.dispatch('addEvent', {
        date: this.date,
        startingHour: this.startingHour,
        startingMinutes: this.startingMinutes,
        duration: this.duration,
        category: this.category,
        type: this.type,
        students: [...this.selectedStudents],
        user_uid: getAuth().currentUser.uid
      }).then(() => {
        this.initForm();
        this.$emit('closeModal')
      }).catch(err => {
        console.error("Echec de la création de la leçon : " + err)
      })
    },
    resetSelectedStudents() {
      this.selectedStudents = [];
    },
    selectStudent(student) {
      // console.log(student)
      this.selectedStudents.push(student);
    },
    unselectStudent(student) {
      _remove(this.selectedStudents, student);
    },
    getFormatedDate() {
      return moment(this.date).format('DD/MM/YYYY');
    }
  }
}
</script>

<style scoped lang="scss">
.event-form-card {

  .modal-card-head {

  }

  .card-content {

    .time-params {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .date-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title-date {

        }

        .date-picker {

        }
      }

      .hour-area {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .hour-group {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .input-hour {
            width: 50px;
          }
        }

        .hour-points {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 35px;
        }
      }
    }

    .classe-param {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 1rem;


      .type {
        display: flex;
        flex-direction: column;
      }

      .category {
        display: flex;
        flex-direction: column;
      }

      .duration {
        display: flex;
        flex-direction: column;
      }
    }

    .students {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .selected-stuents {
        border: solid 2px lightgray;
        padding: 1rem;
        width: 100%;
        max-width: 500px;
        min-height: 100px;

        .warning {
          font-weight: bold;
          color: orange;
        }

        .selected-students-chip {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          // TODO : refacto la chip qui deforme tout
          .chip {
            display: inline-block;
            padding: 0 25px;
            height: 50px;
            font-size: 16px;
            line-height: 50px;
            border-radius: 25px;
            border: solid 2px #e2c2ff;
            margin: 5px;
            width: 300px;
          }

          .close-btn {
            padding-left: 10px;
            color: #888;
            font-weight: bold;
            float: right;
            font-size: 20px;
            cursor: pointer;
          }

          .close-btn:hover {
            color: #000;
          }
        }
      }

      .unselected-students {
        border: solid 2px lightgray;
        margin: 1rem;
        padding: 1rem;
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .student-name {
          margin-top: 5px;
        }

      }

    }

  }

  .card-footer {
    padding-top: 1rem;
  }

}

select {
  min-width: 100px;
  width: auto;
  text-align: center;
  margin-left: 5px;
}

input {
  text-align: center;
  min-width: 300px;
  width: auto;
}
</style>