import {addDoc, collection, getDocs, query, where, doc, updateDoc, deleteDoc} from "firebase/firestore";
import {firestoreDb} from "../main";
import store from "../store";

const EVENTS_COLLECTION = 'events';

function getAll() {
    const q = query(collection(firestoreDb, EVENTS_COLLECTION), where("user_uid", "==", store.getters.getAuthenticatedUser.uid));
    return getDocs(q);
}

function getToday() {
    let start = new Date('2022-04-14');
    let end = new Date('2022-04-15');
    const q = query(collection(firestoreDb, EVENTS_COLLECTION),
        where('user_uid', '==', store.getters.getAuthenticatedUser.uid),
        where('date', '>',start),
        where('date', '<', end)
    );
    return getDocs(q);
}

function createEvent(event) {
    return addDoc(collection(firestoreDb, EVENTS_COLLECTION), event);
}

function updateEvent(event) {
    const docRef = doc(firestoreDb, EVENTS_COLLECTION, event.id);
    return updateDoc(docRef, {...event.toPatch});
}

function deleteEvent(eventId) {
    const docRef = doc(firestoreDb, EVENTS_COLLECTION, eventId);
    return deleteDoc(docRef)
}

export const eventsService = {
    getAll,
    createEvent,
    updateEvent,
    deleteEvent,
    getToday,
};