import moment from "moment";

function getStartHourClass(event) {
    let startHour = `start-${event.startingHour}`;
    if (event.startingMinutes > 0) {
        startHour += `-${event.startingMinutes * 10}`
    }
    return startHour;
}

function getStartHourToDisplay(event) {
    let startHour = `${event.startingHour}`;
    if (event.startingMinutes > 0) {
        startHour += `:30`
    } else {
        startHour += `:00`
    }
    return startHour;
}

function getEndHourClass(event) {
    const startHour = parseFloat(event.startingHour) + parseFloat(event.startingMinutes);
    let endHour;
    if ((Number.isInteger(Number(startHour)) && Number.isInteger(Number(event.duration)))
        || (!Number.isInteger(Number(startHour)) && !Number.isInteger(Number(event.duration)))) {
        const endH = startHour + parseFloat(event.duration);
        endHour = `end-${endH}`;
    } else {
        const endH = Math.trunc(startHour) + Math.trunc(parseFloat(event.duration));
        endHour = `end-${endH}-5`;
    }
    return endHour;
}

function getEndHourToDisplay(event) {
    const startHour = parseFloat(event.startingHour) + parseFloat(event.startingMinutes);
    let endHour;
    if ((Number.isInteger(Number(startHour)) && Number.isInteger(Number(event.duration)))
        || (!Number.isInteger(Number(startHour)) && !Number.isInteger(Number(event.duration)))) {
        const endH = startHour + parseFloat(event.duration);
        endHour = `${endH}:00`;
    } else {
        const endH = Math.trunc(startHour) + Math.trunc(parseFloat(event.duration));
        endHour = `${endH}:30`;
    }
    return endHour;
}

function addToDays(days, event) {
    const eventDate = moment.unix(event.date.seconds).format("DD/MM/YYYY");
    let currentDay = days.filter(d => d.date === eventDate);
    if (currentDay.length) {
        currentDay[0].events.push({
            id: event.id,
            eventClass: `${event.startHourClass} ${event.endHourClass}`,
            startHourToDisplay: event.startHourToDisplay,
            endHourToDisplay: event.endHourToDisplay,
            type: `${event.type}-${event.category}`,
            title: event.title,
            time: event.duration,
            students: event.students
        });
    }
}

function mapEventsToSchedule(week, events) {
    let days = [];

    week.forEach(day => {
        const date = moment(day, "DD/MM/YYYY");
        days.push({
            date: day,
            dayName: date.format("dddd"),
            dayNumber: date.format("D"),
            dayClass: date.format("ddd"),
            events: []
        });
    })

    events.forEach(event => {
        event.startHourClass = getStartHourClass(event);
        event.endHourClass = getEndHourClass(event);
        event.startHourToDisplay = getStartHourToDisplay(event);
        event.endHourToDisplay = getEndHourToDisplay(event);
        addToDays(days, event);
    });

    return days;
}

export const eventMapperUtils = {
    mapEventsToSchedule,
};