import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {initializeApp} from 'firebase/app'
import { getFirestore } from "firebase/firestore";
import store from "./store";
import {getAuth} from "firebase/auth";
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import './assets/main.scss'

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBpOi99_HSrpzujn_7ds5DuAQpVsJokLTM",
    authDomain: "planning-23c88.firebaseapp.com",
    projectId: "planning-23c88",
    storageBucket: "planning-23c88.appspot.com",
    messagingSenderId: "22622723454",
    appId: "1:22622723454:web:ced6f69624e64cbc3b672f"
};
initializeApp(firebaseConfig);
export const firestoreDb = getFirestore();

// init auth to firebase for app
getAuth()

createApp(App)
    .use(router)
    .use(store)
    .use(VCalendar, {})
    .mount('#app');


