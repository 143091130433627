import {getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword} from 'firebase/auth';

function login(email, password) {
    return signInWithEmailAndPassword(getAuth(), email, password);
}

function logout() {
    return signOut(getAuth());
}

function register(email, password) {
    return createUserWithEmailAndPassword(getAuth(), email, password)
}

export const authService = {
    login,
    logout,
    register,
};