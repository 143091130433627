import {addDoc, collection, getDocs, query, where, doc, updateDoc, deleteDoc} from "firebase/firestore";
import {firestoreDb} from "../main";
import store from "../store";


const STUDENTS_COLLECTION = 'students';

function getAll() {
    const q = query(collection(firestoreDb, STUDENTS_COLLECTION), where("user_uid", "==", store.getters.getAuthenticatedUser.uid));
    return getDocs(q);
}

function createStudent(student) {
    return addDoc(collection(firestoreDb, STUDENTS_COLLECTION), student);
}

function updateStudent(student) {
    const docRef = doc(firestoreDb, STUDENTS_COLLECTION, student.id);
    return updateDoc(docRef, {...student.toPatch});
}

function deleteStudent(studentId) {
    const docRef = doc(firestoreDb, STUDENTS_COLLECTION, studentId);
    return deleteDoc(docRef)
}

export const studentsService = {
    getAll,
    createStudent,
    updateStudent,
    deleteStudent,
};