import {createRouter, createWebHashHistory} from 'vue-router'
import store from "../store";
import Schedule from "../views/Schedule";

const routes = [
    {
        path: '/',
        name: 'Schedule',
        component: Schedule
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/students',
        name: 'Students',
        component: () => import('../views/Students.vue')
    },
    // otherwise redirect to home
    {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
        redirect: '/',
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.path !== '/login' && !store.getters.getAuthenticatedUser) next({path: '/login'})
    next()
})

export default router
