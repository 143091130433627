import {createStore} from 'vuex'
import {
    DECREMENT_SELECTED_WEEK,
    INCREMENT_SELECTED_WEEK,
    LOGIN,
    LOGOUT, PURGE_EVENTS, PURGE_STUDENTS,
    REFRESH_EVENTS,
    REFRESH_STUDENTS,
    SET_FIRST_DAY,
    SET_LAST_DAY,
    SET_SELECTED_DAYS,
    SET_SELECTED_MONTH,
    SET_SELECTED_WEEK
} from "./mutation-types";
import {authService, eventsService, studentsService, timeService} from "../_services";
import router from "../router";
import {eventMapperUtils} from "../utils/EventMapperUtils";

const store = createStore({
    state: {
        students: [],
        classes: [],
        // Times states
        selectedWeek: null,
        selectedDays: null,
        selectedMonth: null,
        firstDayOfSelectedWeek: null,
        lastDayOfSelectedWeek: null,

        // Authentication states
        authenticatedUser: null,
    },
    getters: {
        // Students getters
        getStudents(state) {
            return state.students;
        },
        getActiveStudents(state) {
            return state.students.filter(s => !!s.enable);
        },
        getStudentByName: (state) => (name) => {
            if (name && name.length) {
                return state.students.filter(s => s.enable && (s.firstname.toLowerCase().startsWith(name.toLowerCase())
                    || s.lastname.toLowerCase().startsWith(name.toLowerCase())))
            } else {
                return state.students.filter(s => !!s.enable);
            }
        },
        getUnselectedStudentsByName: (state) => (name, category, selectedStudents) => {
            let unselectedStudents = state.students.filter(s => !selectedStudents.includes(s));
            unselectedStudents = unselectedStudents.filter(s => s.category === category);
            if (name && name.length) {
                return unselectedStudents.filter(s => s.enable && (s.firstname.toLowerCase().startsWith(name.toLowerCase())
                    || s.lastname.toLowerCase().startsWith(name.toLowerCase())))
            } else {
                return unselectedStudents.filter(s => !!s.enable);
            }
        },

        // Authentication getters
        getAuthenticatedUser(state) {
            return state.authenticatedUser;
        },

        // Classes getters
        getClasses(state) { // return all classe
            return state.classes;
        },

        // Time getters
        getSelectedWeek(state) { // return classes days
            return state.selectedWeek;
        },
        getSelectedDays(state) { // return classes months
            return state.selectedDays;
        },
        getSelectedMonth(state) { // return month of selected week
            return state.selectedMonth;
        },
        getFirstDayOfSelectedWeek(state) { // return first day of selected week
            return state.firstDayOfSelectedWeek;
        },
        getLastDayOfSelectedWeek(state) { // return last day of selected week
            return state.lastDayOfSelectedWeek;
        },
    },
    mutations: {
        // Students mutations
        [REFRESH_STUDENTS](state, students) {
            state.students = students;
        },
        [PURGE_STUDENTS](state) {
            state.students = [];
        },

        // Events mutations
        [REFRESH_EVENTS](state, classes) {
            state.classes = classes;
        },
        [PURGE_EVENTS](state) {
            state.classes = [];
        },

        // Authentication mutations
        [LOGIN](state, user) {
            state.authenticatedUser = user;
        },
        [LOGOUT](state) {
            state.authenticatedUser = null;

        },

        // Time mutations
        [SET_SELECTED_WEEK](state, weekNumber) {
            state.selectedWeek = weekNumber;

        },
        [SET_SELECTED_DAYS](state, days) {
            state.selectedDays = days;
        },
        [SET_FIRST_DAY](state, day) {
            state.firstDayOfSelectedWeek = day;
        },
        [SET_LAST_DAY](state, day) {
            state.lastDayOfSelectedWeek = day;
        },
        [SET_SELECTED_MONTH](state, month) {
            state.selectedMonth = month;
        },
        [DECREMENT_SELECTED_WEEK](state) {
            state.selectedWeek -= 1;
        },
        [INCREMENT_SELECTED_WEEK](state) {
            state.selectedWeek += 1;
        }
    },
    actions: {
        // Students actions
        getStudents({commit}) {
            studentsService.getAll()
                .then(querySnapshot => {
                    let students = [];
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((document) => {
                            let id = document.id;
                            students.push({id, ...document.data()})
                        });
                    }
                    commit(REFRESH_STUDENTS, students);
                })
                .catch(error => console.error('Erreur lors de la récupération de la liste des étudiants : ' + error));
        },
        addStudent({dispatch}, student) {
            studentsService.createStudent(student)
                .then(() => dispatch('getStudents'))
                .catch(error => console.error('Erreur lors de la création de l\'éléve : ' + error));
        },
        updateStudent({dispatch}, student) {
            studentsService.updateStudent(student)
                .then(() => dispatch('getStudents'))
                .catch(error => console.error('Erreur lors de la mise à jour de l\'éléve : ' + error));
        },
        deleteStudent({dispatch}, studentId) {
            studentsService.deleteStudent(studentId)
                .then(() => dispatch('getStudents'))
                .catch(error => console.error("Erreur lors de la suppression de l'élève : " + error));
        },

        // Event actions
        getEvents({commit, state}) {
            let week = timeService.getWeekDatesByWeekNumber(state.selectedWeek);
            eventsService.getAll()
                .then(querySnapshot => {
                    let events = [];
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((document) => {
                            let id = document.id;
                            events.push({id, ...document.data()})
                        });
                    }
                    commit(REFRESH_EVENTS, eventMapperUtils.mapEventsToSchedule(week, events));
                })
                .catch(error => console.error('Erreur lors de la récupération de la liste des événements : ' + error));
        },
        addEvent({dispatch}, event) {
            eventsService.createEvent(event)
                .then(() => {
                    dispatch('getEvents')
                })
                .catch(error => console.error('Erreur lors de la création de l\'événement : ' + error));
        },
        updateEvent({dispatch}, event) {
            eventsService.updateEvent(event)
                .then(() => dispatch('getEvents'))
                .catch(error => console.error('Erreur lors de la mise à jour de l\'événement: ' + error));
        },
        deleteEvent({dispatch}, event) {
            eventsService.deleteEvent(event)
                .then(() => dispatch('getEvents'))
                .catch(error => console.error('Erreur lors de la suppression de l\'événement : ' + error));
        },

        // Authentication actions
        login({commit}, data) {
            authService.login(data.email, data.password)
                .then(data => {
                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    commit(LOGIN, data.user);
                    commit(REFRESH_EVENTS);
                    router.push("/");
                })
                .catch(error => console.error('Erreur lors de la connexion : ' + error.message))
        },
        logout({commit}) {
            authService.logout()
                .then(() => {
                    localStorage.removeItem('currentUser');
                    commit(LOGOUT);
                    commit(PURGE_STUDENTS);
                    commit(PURGE_EVENTS)
                    router.push("/login")
                })
                .catch(error => console.error('Erreur lors de la deconnexion : ' + error.message))
        },
        register({commit}, data) {
            authService.register(data.email, data.password)
                .then(data => {
                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    commit(LOGIN, data.user);
                })
                .catch(error => console.error('Erreur lors de l\'inscription : ' + error.message))
        },
        retrieveFromLocalStorage({commit}) {
            const userFromLocalStorage = localStorage.getItem('currentUser');
            if (userFromLocalStorage) {
                commit(LOGIN, JSON.parse(userFromLocalStorage))
            }
        },

        // Time actions
        getCurrentWeek({commit, dispatch}) {
            commit(SET_SELECTED_WEEK, timeService.getCurrentWeekNumber());
            dispatch('refreshTimeData');
        },
        getDaysOfWeek({commit, state}) {
            commit(SET_SELECTED_DAYS, timeService.getWeekDaysByWeekNumber(state.selectedWeek));
        },
        getCurrentMonth({commit, state}) {
            commit(SET_SELECTED_MONTH, timeService.getMonthOfWeek(state.selectedMonth));
        },
        getFirstDay({commit, state}) {
            commit(SET_FIRST_DAY, timeService.getFirstDayOfWeek(state.selectedWeek));
        },
        getLastDay({commit, state}) {
            commit(SET_LAST_DAY, timeService.getLastDayOfWeek(state.selectedWeek));
        },
        decrementSelectedWeek({commit, dispatch}) {
            commit(DECREMENT_SELECTED_WEEK);
            dispatch('refreshTimeData');
        },
        incrementSelectedWeek({commit, dispatch}) {
            commit(INCREMENT_SELECTED_WEEK);
            dispatch('refreshTimeData');
        },
        refreshTimeData({dispatch}) {
            dispatch('getDaysOfWeek');
            dispatch('getCurrentMonth');
            dispatch('getFirstDay');
            dispatch('getLastDay');
            dispatch('getEvents');
        }
    }
})

export default store;