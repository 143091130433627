<template>
  <button class="button is-primary" @click="showPreviousWeek">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </button>
  <input disabled type="text" v-model="currentWeekToDisplay" class="input input-displaying-dates">
  <button class="button is-primary" @click="showNewtWeek">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</template>

<script>
import store from "../../store";
import {mapGetters} from "vuex";

export default {
  name: "WeekSwitch",
  data() {
    return {
      editing: null,
    }
  },
  computed: {
    ...mapGetters({
      firstDay: 'getFirstDayOfSelectedWeek',
      lastDay: 'getLastDayOfSelectedWeek',
    }),
    currentWeekToDisplay() {
      return `${this.firstDay} -> ${this.lastDay}`;
    }
  },
  methods: {
    showPreviousWeek() {
      store.dispatch('decrementSelectedWeek');
    },
    showNewtWeek() {
      store.dispatch('incrementSelectedWeek');
    },
  }
}
</script>

<style scoped>

.input-displaying-dates {
  width: 250px;
  text-align: center;
}

</style>