<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img src="../assets/logo.png">
      </a>
      <span class="navbar-burger burger" v-if="getAuthenticatedUser" v-on:click="this.manageBurger" aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </span>
    </div>
    <div id="navbarMenu" class="navbar-menu">
      <div class="navbar-start">
        <router-link to="/" class="navbar-item" v-if="this.getAuthenticatedUser">
          <ion-icon name="calendar-outline"></ion-icon>
          Planning
        </router-link>
        <router-link to="/students" class="navbar-item" v-if="this.getAuthenticatedUser">
          <ion-icon name="person-outline"></ion-icon>
          Elèves
        </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-info is-light" v-if="getAuthenticatedUser" @click="logout">
              <ion-icon class="deco basicon" name="power-outline"></ion-icon>
              Déconnexion
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import router from "../router";
import {mapGetters} from "vuex";
import store from "../store";

export default {
  name: "Navbar",
  methods: {
    logout() {
      store.dispatch('logout');
      router.push("/login");
    },
    manageBurger() {
      let burger = document.querySelector('.burger');
      let nav = document.querySelector('#' + burger.dataset.target)

      burger.classList.toggle('is-active');
      nav.classList.toggle('is-active');
    },
    closeBurger() {
      let burger = document.querySelector('.burger');
      let nav = document.querySelector('#' + burger.dataset.target)

      burger.classList.remove('is-active');
      nav.classList.remove('is-active');
    }
  },
  computed: {
    ...mapGetters([
      'getAuthenticatedUser',
    ]),
  },
}
</script>

<style scoped>

.deco {
  margin-right: 5px;
  color: red;
}

</style>