<template>
  <div class="schedule-view">
    <div class="action-area">
      <div class="left-panel">
        <week-switch></week-switch>
      </div>
      <div class="right-panel">

        <div :class="{'is-active': showEventForm}" class="modal">
          <div class="modal-background"></div>
          <div class="modal-content">
            <EventForm @closeModal="closeEventForm"></EventForm>
          </div>
          <button class="modal-close is-large" @click="closeEventForm" aria-label="close"></button>
        </div>

        <button class="button is-primary" @click="openEventForm">
          <ion-icon class="add-icon" name="add-circle-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div class="calendar">
      <time-line></time-line>
      <div class="days">
        <div class="day" v-for="day in (classes)" :key="day">
          <div class="date">
            <span class="date-day">{{ day.dayName }}</span> <span class="date-num">{{ day.dayNumber }}</span>
          </div>
          <div class="events">
            <!--            mes leçons-->
            <div v-for="event in day.events" :id="`event-${event.id}`" :key="event.id" class="event"
                 :class="[event.eventClass, event.type]">
              <p class="title" v-for="student in event.students" :key="student.id">{{ student.firstname }}
                {{ student.lastname }}</p>
              <p class="time">{{ event.startHourToDisplay }}:{{ event.endHourToDisplay }}</p>
              <div class="delete-panel is-hidden" :id="`delete-event-${event.id}`">
                <button class="button delete-btn cancel" @click="toogleDeletePanel(event.id)">
                  <ion-icon name="close-circle-outline"></ion-icon>
                </button>
                <button class="button delete-btn trash" @click="deleteEvent(event.id)">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "../store";
import WeekSwitch from "../components/schedule/WeekSwitch";
import TimeLine from "../components/schedule/TimeLine";
import EventForm from "../components/schedule/EventForm";
import * as Hammer from 'hammerjs'

export default {
  name: "Schedule",
  components: {EventForm, TimeLine, WeekSwitch},
  data() {
    return {
      showEventForm: false,
    }
  },
  mounted() {
    store.dispatch('getCurrentWeek');
  },
  updated() {
    this.mapEventFromHammer()
  },
  computed: {
    ...mapGetters({
      selectedWeek: 'getSelectedWeek',
      selectedDays: 'getSelectedDays',
      selectedMonth: 'getSelectedMonth',
      classes: 'getClasses',
    }),
  },
  methods: {
    openEventForm() {
      this.showEventForm = true;
    },
    closeEventForm() {
      this.showEventForm = false;
    },
    mapEventFromHammer() {
      let classesPressManagers = [];
      this.classes.forEach(day => {
        day.events.forEach(event => {
          let eventEntity = document.getElementById(`event-${event.id}`);
          let manager = new Hammer.Manager(eventEntity);
          let press = new Hammer.Press({
            time: 500
          });
          manager.add(press);
          manager.on('press', function () {
            const panel = document.getElementById(`delete-event-${event.id}`);
            panel.classList.toggle('is-hidden')
          });
          classesPressManagers.push(manager);
        })
      })
    },
    deleteEvent(eventId) {
      if (confirm('Supprimer cette leçon ?')) {
        store.dispatch('deleteEvent', eventId);
      }
    },
    toogleDeletePanel(id) {
      const panel = document.getElementById(`delete-event-${id}`);
      panel.classList.toggle('is-hidden')
    }
  }
}
</script>

<style scoped lang="scss">

:root {
  --numDays: 5;
  --numHours: 10;
  --timeHeight: 60px;
  --calBgColor: #fff1f8;
  --eventBorderColor: #f2d3d8;
  --eventColor1: #ffd6d1;
  --eventColor2: #fafaa3;
  --eventColor3: #e2f8ff;
  --eventColor4: #d1ffe6;
}

.schedule-view {
  width: 100%;
  height: 90vh;

  .action-area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .left-panel {

    }

    .right-panel {

      .add-icon {
        width: 30px;
      }

      .modal-content {
        width: auto !important;
        max-width: 100vw;
      }

    }
  }

  .calendar {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 1fr;
    height: 100%;

    .days {
      display: grid;
      grid-column: 2;
      gap: 5px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    .events {
      display: grid;
      grid-template-rows: repeat(27, 30px);
      border-radius: 5px;
      background: #FFF1F8FF;
    }

    /*Place on Timeline*/
    .start-7 {
      grid-row-start: 1;
    }

    .start-7-5 {
      grid-row-start: 2;
    }

    .start-8 {
      grid-row-start: 3;
    }

    .start-8-5 {
      grid-row-start: 4;
    }

    .start-9 {
      grid-row-start: 5;
    }

    .start-9-5 {
      grid-row-start: 6;
    }

    .start-10 {
      grid-row-start: 7;
    }

    .start-10-5 {
      grid-row-start: 8;
    }

    .start-11 {
      grid-row-start: 9;
    }

    .start-11-5 {
      grid-row-start: 10;
    }

    .start-12 {
      grid-row-start: 11;
    }

    .start-12-5 {
      grid-row-start: 12;
    }

    .start-13 {
      grid-row-start: 13;
    }

    .start-13-5 {
      grid-row-start: 14;
    }

    .start-14 {
      grid-row-start: 15;
    }

    .start-14-5 {
      grid-row-start: 16;
    }

    .start-15 {
      grid-row-start: 17;
    }

    .start-15-5 {
      grid-row-start: 18;
    }

    .start-16 {
      grid-row-start: 19;
    }

    .start-16-5 {
      grid-row-start: 20;
    }

    .start-17 {
      grid-row-start: 21;
    }

    .start-17-5 {
      grid-row-start: 22;
    }

    .start-18 {
      grid-row-start: 23;
    }

    .start-18-5 {
      grid-row-start: 24;
    }

    .start-19 {
      grid-row-start: 25;
    }

    .end-7-5 {
      grid-row-end: 2;
    }

    .end-8 {
      grid-row-end: 3;
    }

    .end-8-5 {
      grid-row-end: 4;
    }

    .end-9 {
      grid-row-end: 5;
    }

    .end-9-5 {
      grid-row-end: 6;
    }

    .end-10 {
      grid-row-end: 7;
    }

    .end-10-5 {
      grid-row-end: 8;
    }

    .end-11 {
      grid-row-end: 9;
    }

    .end-11-5 {
      grid-row-end: 10;
    }

    .end-12 {
      grid-row-end: 11;
    }

    .end-12-5 {
      grid-row-end: 12;
    }

    .end-13 {
      grid-row-end: 13;
    }

    .end-13-5 {
      grid-row-end: 14;
    }

    .end-14 {
      grid-row-end: 15;
    }

    .end-14-5 {
      grid-row-end: 16;
    }

    .end-15 {
      grid-row-end: 17;
    }

    .end-15-5 {
      grid-row-end: 18;
    }

    .end-16 {
      grid-row-end: 19;
    }

    .end-16-5 {
      grid-row-end: 20;
    }

    .end-17 {
      grid-row-end: 21;
    }

    .end-17-5 {
      grid-row-end: 22;
    }

    .end-18 {
      grid-row-end: 23;
    }

    .end-18-5 {
      grid-row-end: 24;
    }

    .end-19 {
      grid-row-end: 25;
    }

    .end-19-5 {
      grid-row-end: 26;
    }

    .end-20 {
      grid-row-end: 27;
    }

    /*Event*/

    .title {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 0.25rem;
      color: white;
    }

    .event {
      border: 1px solid #F2D3D8FF;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0 0.5rem;
      background: white;
      position: relative;

      .delete-panel {
        position: absolute;
        left: 3%;
        top: 3%;
        background: rgba(0, 0, 0, 0.8);
        width: 94%;
        height: 94%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .delete-btn {
          width: 100%;
          height: 50%;
          background: none;
          font-size: 25px;
          border: none;
        }

        .cancel {
          color: darkgray;
        }

        .trash {
          color: orangered;
        }
      }
    }

    /*Global / Etc*/
    .classe-B {
      background: #e19fff;
      color: whitesmoke;
    }

    .preameet-B,
    .classe-AAC {
      background: #ff56e8;
      color: whitesmoke;
    }

    .classe-BE {
      background: orange;
      color: whitesmoke;
    }

    .classe-A,
    .classe-A1,
    .classe-A2,
    .classe-AM {
      background: dodgerblue;
      color: whitesmoke;
    }


    .exam-B {
      background: #4f1e68;
      color: whitesmoke;
    }

    .exam-BE {
      background: #a75c02;
      color: whitesmoke;
    }

    .exam-A,
    .exam-A1,
    .exam-A2,
    .exam-AM {
      background: #1e43ff;
      color: whitesmoke;
    }

    .pedameet-B {
      background: #560032;
      color: whitesmoke;
    }

    .date {
      gap: 1em;
    }

    .date-num {
      font-size: 1rem;
      font-weight: 600;
      display: inline;
    }

    .date-day {
      display: inline;
      font-size: 1rem;
      font-weight: 100;
    }

  }
}
</style>